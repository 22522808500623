import {
  TEAM_MEMBERS,
  ACTIVE_TEAM_MEMBERS,
  TEAM_DETAILS,
  TEAM_CLIENT_ENTRANT_COUNT,
  ADD_TEAM_MEMBER_MODAL,
  ADD_TEAM_MODAL,
} from "../actions";

export function teamMembers(state = {}, action) {
  if (action.type === TEAM_MEMBERS) {
    return action.payload;
  }
  return state;
}

export function activeTeamMembers(state = {}, action) {
  if (action.type === ACTIVE_TEAM_MEMBERS) {
    return action.payload;
  }
  return state;
}

export function teamDetails(state = {}, action) {
  if (action.type === TEAM_DETAILS) {
    return action.payload;
  }
  return state;
}

export function teamClientEntrantCount(state = {}, action) {
  if (action.type === TEAM_CLIENT_ENTRANT_COUNT) {
    return action.payload;
  }
  return state;
}

export function teamMemberAddModal(state = {}, action) {
  if (action.type === ADD_TEAM_MEMBER_MODAL) {
    return action.payload;
  }
  return state;
}

export function teamAddModal(state = {}, action) {
  if (action.type === ADD_TEAM_MODAL) {
    return action.payload;
  }
  return state;
}
