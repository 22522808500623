/* eslint-disable no-unused-vars */
import { SAVE_AVATAR_OPTION_DATA, FETCH_AVATAR_OPTION_DATA } from "../actions";

export const AvatarReducer = (state = {}, action) => {
  switch (action.type) {
    case SAVE_AVATAR_OPTION_DATA:
      return action.payload;

    default:
      return state;
  }
};

export const FetchAvatarReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_AVATAR_OPTION_DATA:
      return action.payload;

    default:
      return state;
  }
};
