import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { GooSpinner } from "react-spinners-kit";

const Loading = (props) => {
  var { session, eventDataOnly } = props;

  return (
    <div className='loader-section'>
      <GooSpinner
        size={80}
        // color={eventDataOnly?.themecolor ? eventDataOnly.themecolor : '#2676ea'}
        color='#2676ea'
        loading={true}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    // eventDataOnly: state.eventDataOnly
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Loading);
