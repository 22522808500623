export var getHeaders = session => ({
  headers: {
    'X-DreamFactory-Api-Key': session.apiKey,
    ...(session.sessionToken && session.sessionToken != ''
      ? { 'X-DreamFactory-Session-Token': session.sessionToken }
      : {}),
    contentType: 'application/json; charset=utf-8'
  }
});

export var getMultiPartHeaders = session => ({
  headers: {
    'X-DreamFactory-Api-Key': session.apiKey,
    'X-DreamFactory-Session-Token': session.sessionToken,
    'Content-Type': 'application/x-www-form-urlencoded'
  }
});

export var getHeadersWithParams = (session, params) => ({
  headers: {
    'X-DreamFactory-Api-Key': session.apiKey,
    'X-DreamFactory-Session-Token': session.sessionToken,
    'Content-Type': 'multipart/form-data'
  },
  params: params
});

export var getHeadersMultiPart = session => ({
  headers: {
    'X-DreamFactory-Api-Key': session.apiKey,
    'X-DreamFactory-Session-Token': session.sessionToken,
    'Content-Type':
      'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s'
  }
});

export var getImageHeaders = session => ({
  headers: {
    'X-DreamFactory-Api-Key': session.apiKey,
    'X-DreamFactory-Session-Token': session.sessionToken,
    contentType: 'application/json; charset=utf-8'
  }
});

export var getExtraImageHeaders = (session, filename) => ({
  headers: {
    'X-DreamFactory-Api-Key': session.apiKey,
    'X-DreamFactory-Session-Token': session.sessionToken,
    contentType: 'application/json; charset=utf-8',
    'X-File-Name': filename
  }
});
