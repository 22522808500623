import axios from "axios";
import _ from "lodash";

import {
  deleteRequest,
  getRequest,
  postRequest,
  patchRequest,
} from "../../../components/Common/network-call";

import { BASE_URL, APP_API_KEY } from "../../../components/Common/constant";

export const FAQ_LIST = "FAQ_LIST";

export function fetchFAQ(session, callback) {
  var filter = encodeURI(`(eventtype=${session.eventtype})`);
  var url = `${BASE_URL}api/v2/teamchallenge/_table/faq?filter=(${filter})`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (data) => {
        dispatch({
          type: FAQ_LIST,
          payload: { data: data.resource, count: data.resource.length },
        });
        callback({ success: 1, data: data.resource });
      },
      (error) => {
        callback({ success: 0 });
      }
    );
  };
}
