import react from "react";

//  hairs
// h1
import h1hairOne from "../../assets/side_option_panel/hair/h1/hair_01.png";
import h1hairTwo from "../../assets/side_option_panel/hair/h1/hair_02.png";
import h1hairThree from "../../assets/side_option_panel/hair/h1/hair_03.png";
import h1hairFour from "../../assets/side_option_panel/hair/h1/hair_04.png";
import h1hairFive from "../../assets/side_option_panel/hair/h1/hair_05.png";
import h1hairSix from "../../assets/side_option_panel/hair/h1/hair_06.png";
import h1hairSeven from "../../assets/side_option_panel/hair/h1/hair_07.png";
import h1hairEight from "../../assets/side_option_panel/hair/h1/hair_08.png";
import h1hairNine from "../../assets/side_option_panel/hair/h1/hair_09.png";
import h1hairTen from "../../assets/side_option_panel/hair/h1/hair_10.png";
import h1hairEleven from "../../assets/side_option_panel/hair/h1/hair_11.png";
import h1hairTweleve from "../../assets/side_option_panel/hair/h1/hair_12.png";
import h1hairThirteen from "../../assets/side_option_panel/hair/h1/hair_13.png";
import h1hairFourteen from "../../assets/side_option_panel/hair/h1/hair_14.png";
import h1hairFifteen from "../../assets/side_option_panel/hair/h1/hair_15.png";
import h1hairSixteen from "../../assets/side_option_panel/hair/h1/hair_16.png";
// h2
import h2hairOne from "../../assets/side_option_panel/hair/h2/hair_01.png";
import h2hairTwo from "../../assets/side_option_panel/hair/h2/hair_02.png";
import h2hairThree from "../../assets/side_option_panel/hair/h2/hair_03.png";
import h2hairFour from "../../assets/side_option_panel/hair/h2/hair_04.png";
import h2hairFive from "../../assets/side_option_panel/hair/h2/hair_05.png";
import h2hairSix from "../../assets/side_option_panel/hair/h2/hair_06.png";
import h2hairSeven from "../../assets/side_option_panel/hair/h2/hair_07.png";
import h2hairEight from "../../assets/side_option_panel/hair/h2/hair_08.png";
import h2hairNine from "../../assets/side_option_panel/hair/h2/hair_09.png";
import h2hairTen from "../../assets/side_option_panel/hair/h2/hair_10.png";
import h2hairEleven from "../../assets/side_option_panel/hair/h2/hair_11.png";
import h2hairTweleve from "../../assets/side_option_panel/hair/h2/hair_12.png";
import h2hairThirteen from "../../assets/side_option_panel/hair/h2/hair_13.png";
import h2hairFourteen from "../../assets/side_option_panel/hair/h2/hair_14.png";
import h2hairFifteen from "../../assets/side_option_panel/hair/h2/hair_15.png";
import h2hairSixteen from "../../assets/side_option_panel/hair/h2/hair_16.png";
// h3
import h3hairOne from "../../assets/side_option_panel/hair/h3/hair_01.png";
import h3hairTwo from "../../assets/side_option_panel/hair/h3/hair_02.png";
import h3hairThree from "../../assets/side_option_panel/hair/h3/hair_03.png";
import h3hairFour from "../../assets/side_option_panel/hair/h3/hair_04.png";
import h3hairFive from "../../assets/side_option_panel/hair/h3/hair_05.png";
import h3hairSix from "../../assets/side_option_panel/hair/h3/hair_06.png";
import h3hairSeven from "../../assets/side_option_panel/hair/h3/hair_07.png";
import h3hairEight from "../../assets/side_option_panel/hair/h3/hair_08.png";
import h3hairNine from "../../assets/side_option_panel/hair/h3/hair_09.png";
import h3hairTen from "../../assets/side_option_panel/hair/h3/hair_10.png";
import h3hairEleven from "../../assets/side_option_panel/hair/h3/hair_11.png";
import h3hairTweleve from "../../assets/side_option_panel/hair/h3/hair_12.png";
import h3hairThirteen from "../../assets/side_option_panel/hair/h3/hair_13.png";
import h3hairFourteen from "../../assets/side_option_panel/hair/h3/hair_14.png";
import h3hairFifteen from "../../assets/side_option_panel/hair/h3/hair_15.png";
import h3hairSixteen from "../../assets/side_option_panel/hair/h3/hair_16.png";
// h4
import h4hairOne from "../../assets/side_option_panel/hair/h4/hair_01.png";
import h4hairTwo from "../../assets/side_option_panel/hair/h4/hair_02.png";
import h4hairThree from "../../assets/side_option_panel/hair/h4/hair_03.png";
import h4hairFour from "../../assets/side_option_panel/hair/h4/hair_04.png";
import h4hairFive from "../../assets/side_option_panel/hair/h4/hair_05.png";
import h4hairSix from "../../assets/side_option_panel/hair/h4/hair_06.png";
import h4hairSeven from "../../assets/side_option_panel/hair/h4/hair_07.png";
import h4hairEight from "../../assets/side_option_panel/hair/h4/hair_08.png";
import h4hairNine from "../../assets/side_option_panel/hair/h4/hair_09.png";
import h4hairTen from "../../assets/side_option_panel/hair/h4/hair_10.png";
import h4hairEleven from "../../assets/side_option_panel/hair/h4/hair_11.png";
import h4hairTweleve from "../../assets/side_option_panel/hair/h4/hair_12.png";
import h4hairThirteen from "../../assets/side_option_panel/hair/h4/hair_13.png";
import h4hairFourteen from "../../assets/side_option_panel/hair/h4/hair_14.png";
import h4hairFifteen from "../../assets/side_option_panel/hair/h4/hair_15.png";
import h4hairSixteen from "../../assets/side_option_panel/hair/h4/hair_16.png";
// h5
import h5hairOne from "../../assets/side_option_panel/hair/h5/hair_01.png";
import h5hairTwo from "../../assets/side_option_panel/hair/h5/hair_02.png";
import h5hairThree from "../../assets/side_option_panel/hair/h5/hair_03.png";
import h5hairFour from "../../assets/side_option_panel/hair/h5/hair_04.png";
import h5hairFive from "../../assets/side_option_panel/hair/h5/hair_05.png";
import h5hairSix from "../../assets/side_option_panel/hair/h5/hair_06.png";
import h5hairSeven from "../../assets/side_option_panel/hair/h5/hair_07.png";
import h5hairEight from "../../assets/side_option_panel/hair/h5/hair_08.png";
import h5hairNine from "../../assets/side_option_panel/hair/h5/hair_09.png";
import h5hairTen from "../../assets/side_option_panel/hair/h5/hair_10.png";
import h5hairEleven from "../../assets/side_option_panel/hair/h5/hair_11.png";
import h5hairTweleve from "../../assets/side_option_panel/hair/h5/hair_12.png";
import h5hairThirteen from "../../assets/side_option_panel/hair/h5/hair_13.png";
import h5hairFourteen from "../../assets/side_option_panel/hair/h5/hair_14.png";
import h5hairFifteen from "../../assets/side_option_panel/hair/h5/hair_15.png";
import h5hairSixteen from "../../assets/side_option_panel/hair/h5/hair_16.png";
// h6
import h6hairOne from "../../assets/side_option_panel/hair/h6/hair_01.png";
import h6hairTwo from "../../assets/side_option_panel/hair/h6/hair_02.png";
import h6hairThree from "../../assets/side_option_panel/hair/h6/hair_03.png";
import h6hairFour from "../../assets/side_option_panel/hair/h6/hair_04.png";
import h6hairFive from "../../assets/side_option_panel/hair/h6/hair_05.png";
import h6hairSix from "../../assets/side_option_panel/hair/h6/hair_06.png";
import h6hairSeven from "../../assets/side_option_panel/hair/h6/hair_07.png";
import h6hairEight from "../../assets/side_option_panel/hair/h6/hair_08.png";
import h6hairNine from "../../assets/side_option_panel/hair/h6/hair_09.png";
import h6hairTen from "../../assets/side_option_panel/hair/h6/hair_10.png";
import h6hairEleven from "../../assets/side_option_panel/hair/h6/hair_11.png";
import h6hairTweleve from "../../assets/side_option_panel/hair/h6/hair_12.png";
import h6hairThirteen from "../../assets/side_option_panel/hair/h6/hair_13.png";
import h6hairFourteen from "../../assets/side_option_panel/hair/h6/hair_14.png";
import h6hairFifteen from "../../assets/side_option_panel/hair/h6/hair_15.png";
import h6hairSixteen from "../../assets/side_option_panel/hair/h6/hair_16.png";

// eyes
import eyeOne from "../../assets/side_option_panel/eyes/eyes_01.png";
import eyeTwo from "../../assets/side_option_panel/eyes/eyes_02.png";
import eyeThree from "../../assets/side_option_panel/eyes/eyes_03.png";
import eyeFour from "../../assets/side_option_panel/eyes/eyes_04.png";
import eyeFive from "../../assets/side_option_panel/eyes/eyes_05.png";
import eyeSix from "../../assets/side_option_panel/eyes/eyes_06.png";
import eyeSeven from "../../assets/side_option_panel/eyes/eyes_07.png";
import eyeEight from "../../assets/side_option_panel/eyes/eyes_08.png";
import eyeNine from "../../assets/side_option_panel/eyes/eyes_09.png";

// beard
// h1
import h1beardOne from "../../assets/side_option_panel/beard/h1/beard_01.png";
import h1beardThree from "../../assets/side_option_panel/beard/h1/beard_03.png";
import h1beardFour from "../../assets/side_option_panel/beard/h1/beard_04.png";
import h1beardFive from "../../assets/side_option_panel/beard/h1/beard_05.png";
import h1beardSix from "../../assets/side_option_panel/beard/h1/beard_06.png";
import h1beardSeven from "../../assets/side_option_panel/beard/h1/beard_07.png";
import h1beardEight from "../../assets/side_option_panel/beard/h1/beard_08.png";
import h1beardNine from "../../assets/side_option_panel/beard/h1/beard_09.png";
// h2
import h2beardOne from "../../assets/side_option_panel/beard/h2/beard_01.png";
import h2beardThree from "../../assets/side_option_panel/beard/h2/beard_03.png";
import h2beardFour from "../../assets/side_option_panel/beard/h2/beard_04.png";
import h2beardFive from "../../assets/side_option_panel/beard/h2/beard_05.png";
import h2beardSix from "../../assets/side_option_panel/beard/h2/beard_06.png";
import h2beardSeven from "../../assets/side_option_panel/beard/h2/beard_07.png";
import h2beardEight from "../../assets/side_option_panel/beard/h2/beard_08.png";
import h2beardNine from "../../assets/side_option_panel/beard/h2/beard_09.png";
// h3
import h3beardOne from "../../assets/side_option_panel/beard/h3/beard_01.png";
import h3beardThree from "../../assets/side_option_panel/beard/h3/beard_03.png";
import h3beardFour from "../../assets/side_option_panel/beard/h3/beard_04.png";
import h3beardFive from "../../assets/side_option_panel/beard/h3/beard_05.png";
import h3beardSix from "../../assets/side_option_panel/beard/h3/beard_06.png";
import h3beardSeven from "../../assets/side_option_panel/beard/h3/beard_07.png";
import h3beardEight from "../../assets/side_option_panel/beard/h3/beard_08.png";
import h3beardNine from "../../assets/side_option_panel/beard/h3/beard_09.png";
// h4
import h4beardOne from "../../assets/side_option_panel/beard/h4/beard_01.png";
import h4beardThree from "../../assets/side_option_panel/beard/h4/beard_03.png";
import h4beardFour from "../../assets/side_option_panel/beard/h4/beard_04.png";
import h4beardFive from "../../assets/side_option_panel/beard/h4/beard_05.png";
import h4beardSix from "../../assets/side_option_panel/beard/h4/beard_06.png";
import h4beardSeven from "../../assets/side_option_panel/beard/h4/beard_07.png";
import h4beardEight from "../../assets/side_option_panel/beard/h4/beard_08.png";
import h4beardNine from "../../assets/side_option_panel/beard/h4/beard_09.png";
// h5
import h5beardOne from "../../assets/side_option_panel/beard/h5/beard_01.png";
import h5beardThree from "../../assets/side_option_panel/beard/h5/beard_03.png";
import h5beardFour from "../../assets/side_option_panel/beard/h5/beard_04.png";
import h5beardFive from "../../assets/side_option_panel/beard/h5/beard_05.png";
import h5beardSix from "../../assets/side_option_panel/beard/h5/beard_06.png";
import h5beardSeven from "../../assets/side_option_panel/beard/h5/beard_07.png";
import h5beardEight from "../../assets/side_option_panel/beard/h5/beard_08.png";
import h5beardNine from "../../assets/side_option_panel/beard/h5/beard_09.png";
// h6
import h6beardOne from "../../assets/side_option_panel/beard/h6/beard_01.png";
import h6beardThree from "../../assets/side_option_panel/beard/h6/beard_03.png";
import h6beardFour from "../../assets/side_option_panel/beard/h6/beard_04.png";
import h6beardFive from "../../assets/side_option_panel/beard/h6/beard_05.png";
import h6beardSix from "../../assets/side_option_panel/beard/h6/beard_06.png";
import h6beardSeven from "../../assets/side_option_panel/beard/h6/beard_07.png";
import h6beardEight from "../../assets/side_option_panel/beard/h6/beard_08.png";
import h6beardNine from "../../assets/side_option_panel/beard/h6/beard_09.png";

// glasses
import gOne from "../../assets/side_option_panel/glasses/glasses_01.png";
import gThree from "../../assets/side_option_panel/glasses/glasses_03.png";
import gFour from "../../assets/side_option_panel/glasses/glasses_04.png";
import gFive from "../../assets/side_option_panel/glasses/glasses_05.png";
import gSix from "../../assets/side_option_panel/glasses/glasses_06.png";
import gSeven from "../../assets/side_option_panel/glasses/glasses_07.png";
import gEight from "../../assets/side_option_panel/glasses/glasses_08.png";
import gNine from "../../assets/side_option_panel/glasses/glasses_09.png";

// mouth
import mOne from "../../assets/side_option_panel/mouth/mouth_01.png";
import mTwo from "../../assets/side_option_panel/mouth/mouth_02.png";
import mThree from "../../assets/side_option_panel/mouth/mouth_03.png";
import mFour from "../../assets/side_option_panel/mouth/mouth_04.png";
import mFive from "../../assets/side_option_panel/mouth/mouth_05.png";
import mSix from "../../assets/side_option_panel/mouth/mouth_06.png";
import mSeven from "../../assets/side_option_panel/mouth/mouth_07.png";
import mEight from "../../assets/side_option_panel/mouth/mouth_08.png";
import mNine from "../../assets/side_option_panel/mouth/mouth_09.png";
import mTen from "../../assets/side_option_panel/mouth/mouth_10.png";

// shoes
import shoeOne from "../../assets/side_option_panel/shoes/shoes_01.png";
import shoeTwo from "../../assets/side_option_panel/shoes/shoes_02.png";
import shoeThree from "../../assets/side_option_panel/shoes/shoes_03.png";
import shoeFour from "../../assets/side_option_panel/shoes/shoes_04.png";
import shoeFive from "../../assets/side_option_panel/shoes/shoes_05.png";
import shoeSix from "../../assets/side_option_panel/shoes/shoes_06.png";
import shoeSeven from "../../assets/side_option_panel/shoes/shoes_07.png";
import shoeEight from "../../assets/side_option_panel/shoes/shoes_08.png";
import shoeNine from "../../assets/side_option_panel/shoes/shoes_09.png";

// head
// s1
import s1headOne from "../../assets/side_option_panel/heads/s1/head_01.png";
import s1headTwo from "../../assets/side_option_panel/heads/s1/head_02.png";
import s1headThree from "../../assets/side_option_panel/heads/s1/head_03.png";
import s1headFour from "../../assets/side_option_panel/heads/s1/head_04.png";
import s1headFive from "../../assets/side_option_panel/heads/s1/head_05.png";
import s1headSix from "../../assets/side_option_panel/heads/s1/head_06.png";
import s1headSeven from "../../assets/side_option_panel/heads/s1/head_07.png";
// s2
import s2headOne from "../../assets/side_option_panel/heads/s2/head_01.png";
import s2headTwo from "../../assets/side_option_panel/heads/s2/head_02.png";
import s2headThree from "../../assets/side_option_panel/heads/s2/head_03.png";
import s2headFour from "../../assets/side_option_panel/heads/s2/head_04.png";
import s2headFive from "../../assets/side_option_panel/heads/s2/head_05.png";
import s2headSix from "../../assets/side_option_panel/heads/s2/head_06.png";
import s2headSeven from "../../assets/side_option_panel/heads/s2/head_07.png";
// s3
import s3headOne from "../../assets/side_option_panel/heads/s3/head_01.png";
import s3headTwo from "../../assets/side_option_panel/heads/s3/head_02.png";
import s3headThree from "../../assets/side_option_panel/heads/s3/head_03.png";
import s3headFour from "../../assets/side_option_panel/heads/s3/head_04.png";
import s3headFive from "../../assets/side_option_panel/heads/s3/head_05.png";
import s3headSix from "../../assets/side_option_panel/heads/s3/head_06.png";
import s3headSeven from "../../assets/side_option_panel/heads/s3/head_07.png";
// s4
import s4headOne from "../../assets/side_option_panel/heads/s4/head_01.png";
import s4headTwo from "../../assets/side_option_panel/heads/s4/head_02.png";
import s4headThree from "../../assets/side_option_panel/heads/s4/head_03.png";
import s4headFour from "../../assets/side_option_panel/heads/s4/head_04.png";
import s4headFive from "../../assets/side_option_panel/heads/s4/head_05.png";
import s4headSix from "../../assets/side_option_panel/heads/s4/head_06.png";
import s4headSeven from "../../assets/side_option_panel/heads/s4/head_07.png";

// bottom
import bottomOne from "../../assets/side_option_panel/bottoms/bottom_01.png";
import bottomTwo from "../../assets/side_option_panel/bottoms/bottom_02.png";
import bottomThree from "../../assets/side_option_panel/bottoms/bottom_03.png";
import bottomFour from "../../assets/side_option_panel/bottoms/bottom_04.png";
import bottomFive from "../../assets/side_option_panel/bottoms/bottom_05.png";
import bottomSix from "../../assets/side_option_panel/bottoms/bottom_06.png";
import bottomSeven from "../../assets/side_option_panel/bottoms/bottom_07.png";
import bottomEight from "../../assets/side_option_panel/bottoms/bottom_08.png";
import bottomNine from "../../assets/side_option_panel/bottoms/bottom_09.png";
import bottomTen from "../../assets/side_option_panel/bottoms/bottom_10.png";

// eye_brows
// h1
import h1eBrowOne from "../../assets/side_option_panel/eye_brows/h1/eye_brow_01.png";
import h1eBrowTwo from "../../assets/side_option_panel/eye_brows/h1/eye_brow_02.png";
import h1eBrowThree from "../../assets/side_option_panel/eye_brows/h1/eye_brow_03.png";
import h1eBrowFour from "../../assets/side_option_panel/eye_brows/h1/eye_brow_04.png";
import h1eBrowFive from "../../assets/side_option_panel/eye_brows/h1/eye_brow_05.png";
import h1eBrowSix from "../../assets/side_option_panel/eye_brows/h1/eye_brow_06.png";
import h1eBrowSeven from "../../assets/side_option_panel/eye_brows/h1/eye_brow_07.png";
import h1eBrowEight from "../../assets/side_option_panel/eye_brows/h1/eye_brow_08.png";
import h1eBrowNine from "../../assets/side_option_panel/eye_brows/h1/eye_brow_09.png";
import h1eBrowTen from "../../assets/side_option_panel/eye_brows/h1/eye_brow_10.png";
// h2
import h2eBrowOne from "../../assets/side_option_panel/eye_brows/h2/eye_brow_01.png";
import h2eBrowTwo from "../../assets/side_option_panel/eye_brows/h2/eye_brow_02.png";
import h2eBrowThree from "../../assets/side_option_panel/eye_brows/h2/eye_brow_03.png";
import h2eBrowFour from "../../assets/side_option_panel/eye_brows/h2/eye_brow_04.png";
import h2eBrowFive from "../../assets/side_option_panel/eye_brows/h2/eye_brow_05.png";
import h2eBrowSix from "../../assets/side_option_panel/eye_brows/h2/eye_brow_06.png";
import h2eBrowSeven from "../../assets/side_option_panel/eye_brows/h2/eye_brow_07.png";
import h2eBrowEight from "../../assets/side_option_panel/eye_brows/h2/eye_brow_08.png";
import h2eBrowNine from "../../assets/side_option_panel/eye_brows/h2/eye_brow_09.png";
import h2eBrowTen from "../../assets/side_option_panel/eye_brows/h2/eye_brow_10.png";
// h3
import h3eBrowOne from "../../assets/side_option_panel/eye_brows/h3/eye_brow_01.png";
import h3eBrowTwo from "../../assets/side_option_panel/eye_brows/h3/eye_brow_02.png";
import h3eBrowThree from "../../assets/side_option_panel/eye_brows/h3/eye_brow_03.png";
import h3eBrowFour from "../../assets/side_option_panel/eye_brows/h3/eye_brow_04.png";
import h3eBrowFive from "../../assets/side_option_panel/eye_brows/h3/eye_brow_05.png";
import h3eBrowSix from "../../assets/side_option_panel/eye_brows/h3/eye_brow_06.png";
import h3eBrowSeven from "../../assets/side_option_panel/eye_brows/h3/eye_brow_07.png";
import h3eBrowEight from "../../assets/side_option_panel/eye_brows/h3/eye_brow_08.png";
import h3eBrowNine from "../../assets/side_option_panel/eye_brows/h3/eye_brow_09.png";
import h3eBrowTen from "../../assets/side_option_panel/eye_brows/h3/eye_brow_10.png";
// h4
import h4eBrowOne from "../../assets/side_option_panel/eye_brows/h4/eye_brow_01.png";
import h4eBrowTwo from "../../assets/side_option_panel/eye_brows/h4/eye_brow_02.png";
import h4eBrowThree from "../../assets/side_option_panel/eye_brows/h4/eye_brow_03.png";
import h4eBrowFour from "../../assets/side_option_panel/eye_brows/h4/eye_brow_04.png";
import h4eBrowFive from "../../assets/side_option_panel/eye_brows/h4/eye_brow_05.png";
import h4eBrowSix from "../../assets/side_option_panel/eye_brows/h4/eye_brow_06.png";
import h4eBrowSeven from "../../assets/side_option_panel/eye_brows/h4/eye_brow_07.png";
import h4eBrowEight from "../../assets/side_option_panel/eye_brows/h4/eye_brow_08.png";
import h4eBrowNine from "../../assets/side_option_panel/eye_brows/h4/eye_brow_09.png";
import h4eBrowTen from "../../assets/side_option_panel/eye_brows/h4/eye_brow_10.png";
// h5
import h5eBrowOne from "../../assets/side_option_panel/eye_brows/h5/eye_brow_01.png";
import h5eBrowTwo from "../../assets/side_option_panel/eye_brows/h5/eye_brow_02.png";
import h5eBrowThree from "../../assets/side_option_panel/eye_brows/h5/eye_brow_03.png";
import h5eBrowFour from "../../assets/side_option_panel/eye_brows/h5/eye_brow_04.png";
import h5eBrowFive from "../../assets/side_option_panel/eye_brows/h5/eye_brow_05.png";
import h5eBrowSix from "../../assets/side_option_panel/eye_brows/h5/eye_brow_06.png";
import h5eBrowSeven from "../../assets/side_option_panel/eye_brows/h5/eye_brow_07.png";
import h5eBrowEight from "../../assets/side_option_panel/eye_brows/h5/eye_brow_08.png";
import h5eBrowNine from "../../assets/side_option_panel/eye_brows/h5/eye_brow_09.png";
import h5eBrowTen from "../../assets/side_option_panel/eye_brows/h5/eye_brow_10.png";
// h6
import h6eBrowOne from "../../assets/side_option_panel/eye_brows/h6/eye_brow_01.png";
import h6eBrowTwo from "../../assets/side_option_panel/eye_brows/h6/eye_brow_02.png";
import h6eBrowThree from "../../assets/side_option_panel/eye_brows/h6/eye_brow_03.png";
import h6eBrowFour from "../../assets/side_option_panel/eye_brows/h6/eye_brow_04.png";
import h6eBrowFive from "../../assets/side_option_panel/eye_brows/h6/eye_brow_05.png";
import h6eBrowSix from "../../assets/side_option_panel/eye_brows/h6/eye_brow_06.png";
import h6eBrowSeven from "../../assets/side_option_panel/eye_brows/h6/eye_brow_07.png";
import h6eBrowEight from "../../assets/side_option_panel/eye_brows/h6/eye_brow_08.png";
import h6eBrowNine from "../../assets/side_option_panel/eye_brows/h6/eye_brow_09.png";
import h6eBrowTen from "../../assets/side_option_panel/eye_brows/h6/eye_brow_10.png";

// nose
import noseOne from "../../assets/side_option_panel/noses/nose_01.png";
import noseTwo from "../../assets/side_option_panel/noses/nose_02.png";
import noseThree from "../../assets/side_option_panel/noses/nose_03.png";
import noseFour from "../../assets/side_option_panel/noses/nose_04.png";
import noseFive from "../../assets/side_option_panel/noses/nose_05.png";
import noseSix from "../../assets/side_option_panel/noses/nose_06.png";
import noseSeven from "../../assets/side_option_panel/noses/nose_07.png";
import noseEight from "../../assets/side_option_panel/noses/nose_08.png";
import noseNine from "../../assets/side_option_panel/noses/nose_09.png";

// top
// s1
import s1topOne from "../../assets/side_option_panel/tops/s1/top_01.png";
import s1topTwo from "../../assets/side_option_panel/tops/s1/top_02.png";
import s1topThree from "../../assets/side_option_panel/tops/s1/top_03.png";
import s1topFour from "../../assets/side_option_panel/tops/s1/top_04.png";
import s1topFive from "../../assets/side_option_panel/tops/s1/top_05.png";
import s1topSix from "../../assets/side_option_panel/tops/s1/top_06.png";
import s1topSeven from "../../assets/side_option_panel/tops/s1/top_07.png";
import s1topEight from "../../assets/side_option_panel/tops/s1/top_08.png";
import s1topNine from "../../assets/side_option_panel/tops/s1/top_09.png";
// s2
import s2topOne from "../../assets/side_option_panel/tops/s2/top_01.png";
import s2topTwo from "../../assets/side_option_panel/tops/s2/top_02.png";
import s2topThree from "../../assets/side_option_panel/tops/s2/top_03.png";
import s2topFour from "../../assets/side_option_panel/tops/s2/top_04.png";
import s2topFive from "../../assets/side_option_panel/tops/s2/top_05.png";
import s2topSix from "../../assets/side_option_panel/tops/s2/top_06.png";
import s2topSeven from "../../assets/side_option_panel/tops/s2/top_07.png";
import s2topEight from "../../assets/side_option_panel/tops/s2/top_08.png";
import s2topNine from "../../assets/side_option_panel/tops/s2/top_09.png";
// s3
import s3topOne from "../../assets/side_option_panel/tops/s3/top_01.png";
import s3topTwo from "../../assets/side_option_panel/tops/s3/top_02.png";
import s3topThree from "../../assets/side_option_panel/tops/s3/top_03.png";
import s3topFour from "../../assets/side_option_panel/tops/s3/top_04.png";
import s3topFive from "../../assets/side_option_panel/tops/s3/top_05.png";
import s3topSix from "../../assets/side_option_panel/tops/s3/top_06.png";
import s3topSeven from "../../assets/side_option_panel/tops/s3/top_07.png";
import s3topEight from "../../assets/side_option_panel/tops/s3/top_08.png";
import s3topNine from "../../assets/side_option_panel/tops/s3/top_09.png";
// s4
import s4topOne from "../../assets/side_option_panel/tops/s4/top_01.png";
import s4topTwo from "../../assets/side_option_panel/tops/s4/top_02.png";
import s4topThree from "../../assets/side_option_panel/tops/s4/top_03.png";
import s4topFour from "../../assets/side_option_panel/tops/s4/top_04.png";
import s4topFive from "../../assets/side_option_panel/tops/s4/top_05.png";
import s4topSix from "../../assets/side_option_panel/tops/s4/top_06.png";
import s4topSeven from "../../assets/side_option_panel/tops/s4/top_07.png";
import s4topEight from "../../assets/side_option_panel/tops/s4/top_08.png";
import s4topNine from "../../assets/side_option_panel/tops/s4/top_09.png";

// image imports ends

// blank part image var declare
var beardTwo;
var gTwo;

// arrays for individual imported items to hold
// hair
export var h1hairArray = [
  h1hairOne,
  h1hairTwo,
  h1hairThree,
  h1hairFour,
  h1hairFive,
  h1hairSix,
  h1hairSeven,
  h1hairEight,
  h1hairNine,
  h1hairTen,
  h1hairEleven,
  h1hairTweleve,
  h1hairThirteen,
  h1hairFourteen,
  h1hairFifteen,
  h1hairSixteen,
];
export var h2hairArray = [
  h2hairOne,
  h2hairTwo,
  h2hairThree,
  h2hairFour,
  h2hairFive,
  h2hairSix,
  h2hairSeven,
  h2hairEight,
  h2hairNine,
  h2hairTen,
  h2hairEleven,
  h2hairTweleve,
  h2hairThirteen,
  h2hairFourteen,
  h2hairFifteen,
  h2hairSixteen,
];
export var h3hairArray = [
  h3hairOne,
  h3hairTwo,
  h3hairThree,
  h3hairFour,
  h3hairFive,
  h3hairSix,
  h3hairSeven,
  h3hairEight,
  h3hairNine,
  h3hairTen,
  h3hairEleven,
  h3hairTweleve,
  h3hairThirteen,
  h3hairFourteen,
  h3hairFifteen,
  h3hairSixteen,
];
export var h4hairArray = [
  h4hairOne,
  h4hairTwo,
  h4hairThree,
  h4hairFour,
  h4hairFive,
  h4hairSix,
  h4hairSeven,
  h4hairEight,
  h4hairNine,
  h4hairTen,
  h4hairEleven,
  h4hairTweleve,
  h4hairThirteen,
  h4hairFourteen,
  h4hairFifteen,
  h4hairSixteen,
];
export var h5hairArray = [
  h5hairOne,
  h5hairTwo,
  h5hairThree,
  h5hairFour,
  h5hairFive,
  h5hairSix,
  h5hairSeven,
  h5hairEight,
  h5hairNine,
  h5hairTen,
  h5hairEleven,
  h5hairTweleve,
  h5hairThirteen,
  h5hairFourteen,
  h5hairFifteen,
  h5hairSixteen,
];
export var h6hairArray = [
  h6hairOne,
  h6hairTwo,
  h6hairThree,
  h6hairFour,
  h6hairFive,
  h6hairSix,
  h6hairSeven,
  h6hairEight,
  h6hairNine,
  h6hairTen,
  h6hairEleven,
  h6hairTweleve,
  h6hairThirteen,
  h6hairFourteen,
  h6hairFifteen,
  h6hairSixteen,
];

export var eyeArray = [
  eyeOne,
  eyeTwo,
  eyeThree,
  eyeFour,
  eyeFive,
  eyeSix,
  eyeSeven,
  eyeEight,
  eyeNine,
];

// beard
export var h1beardArray = [
  h1beardOne,
  beardTwo,
  h1beardThree,
  h1beardFour,
  h1beardFive,
  h1beardSix,
  h1beardSeven,
  h1beardEight,
  h1beardNine,
];
export var h2beardArray = [
  h2beardOne,
  beardTwo,
  h2beardThree,
  h2beardFour,
  h2beardFive,
  h2beardSix,
  h2beardSeven,
  h2beardEight,
  h2beardNine,
];
export var h3beardArray = [
  h3beardOne,
  beardTwo,
  h3beardThree,
  h3beardFour,
  h3beardFive,
  h3beardSix,
  h3beardSeven,
  h3beardEight,
  h3beardNine,
];
export var h4beardArray = [
  h4beardOne,
  beardTwo,
  h4beardThree,
  h4beardFour,
  h4beardFive,
  h4beardSix,
  h4beardSeven,
  h4beardEight,
  h4beardNine,
];
export var h5beardArray = [
  h5beardOne,
  beardTwo,
  h5beardThree,
  h5beardFour,
  h5beardFive,
  h5beardSix,
  h5beardSeven,
  h5beardEight,
  h5beardNine,
];
export var h6beardArray = [
  h6beardOne,
  beardTwo,
  h6beardThree,
  h6beardFour,
  h6beardFive,
  h6beardSix,
  h6beardSeven,
  h6beardEight,
  h6beardNine,
];

export var glassArray = [
  gOne,
  gTwo,
  gThree,
  gFour,
  gFive,
  gSix,
  gSeven,
  gEight,
  gNine,
];

export var mouthArray = [
  mOne,
  mTwo,
  mThree,
  mFour,
  mFive,
  mSix,
  mSeven,
  mEight,
  mNine,
  mTen,
];

export var shoesArray = [
  shoeOne,
  shoeTwo,
  shoeThree,
  shoeFour,
  shoeFive,
  shoeSix,
  shoeSeven,
  shoeEight,
  shoeNine,
];

// heads
export var s1headsArray = [
  s1headOne,
  s1headTwo,
  s1headThree,
  s1headFour,
  s1headFive,
  s1headSix,
  s1headSeven,
];
export var s2headsArray = [
  s2headOne,
  s2headTwo,
  s2headThree,
  s2headFour,
  s2headFive,
  s2headSix,
  s2headSeven,
];
export var s3headsArray = [
  s3headOne,
  s3headTwo,
  s3headThree,
  s3headFour,
  s3headFive,
  s3headSix,
  s3headSeven,
];
export var s4headsArray = [
  s4headOne,
  s4headTwo,
  s4headThree,
  s4headFour,
  s4headFive,
  s4headSix,
  s4headSeven,
];

export var bottomsArray = [
  bottomOne,
  bottomTwo,
  bottomThree,
  bottomFour,
  bottomFive,
  bottomSix,
  bottomSeven,
  bottomEight,
  bottomNine,
  bottomTen,
];

// eye_brow
export var h1eyeBrowArray = [
  h1eBrowOne,
  h1eBrowTwo,
  h1eBrowThree,
  h1eBrowFour,
  h1eBrowFive,
  h1eBrowSix,
  h1eBrowSeven,
  h1eBrowEight,
  h1eBrowNine,
  h1eBrowTen,
];
export var h2eyeBrowArray = [
  h2eBrowOne,
  h2eBrowTwo,
  h2eBrowThree,
  h2eBrowFour,
  h2eBrowFive,
  h2eBrowSix,
  h2eBrowSeven,
  h2eBrowEight,
  h2eBrowNine,
  h2eBrowTen,
];
export var h3eyeBrowArray = [
  h3eBrowOne,
  h3eBrowTwo,
  h3eBrowThree,
  h3eBrowFour,
  h3eBrowFive,
  h3eBrowSix,
  h3eBrowSeven,
  h3eBrowEight,
  h3eBrowNine,
  h3eBrowTen,
];
export var h4eyeBrowArray = [
  h4eBrowOne,
  h4eBrowTwo,
  h4eBrowThree,
  h4eBrowFour,
  h4eBrowFive,
  h4eBrowSix,
  h4eBrowSeven,
  h4eBrowEight,
  h4eBrowNine,
  h4eBrowTen,
];
export var h5eyeBrowArray = [
  h5eBrowOne,
  h5eBrowTwo,
  h5eBrowThree,
  h5eBrowFour,
  h5eBrowFive,
  h5eBrowSix,
  h5eBrowSeven,
  h5eBrowEight,
  h5eBrowNine,
  h5eBrowTen,
];
export var h6eyeBrowArray = [
  h6eBrowOne,
  h6eBrowTwo,
  h6eBrowThree,
  h6eBrowFour,
  h6eBrowFive,
  h6eBrowSix,
  h6eBrowSeven,
  h6eBrowEight,
  h6eBrowNine,
  h6eBrowTen,
];

export var noseArray = [
  noseOne,
  noseTwo,
  noseThree,
  noseFour,
  noseFive,
  noseSix,
  noseSeven,
  noseEight,
  noseNine,
];

// top
export var s1topArray = [
  s1topOne,
  s1topTwo,
  s1topThree,
  s1topFour,
  s1topFive,
  s1topSix,
  s1topSeven,
  s1topEight,
  s1topNine,
];
export var s2topArray = [
  s2topOne,
  s2topTwo,
  s2topThree,
  s2topFour,
  s2topFive,
  s2topSix,
  s2topSeven,
  s2topEight,
  s2topNine,
];
export var s3topArray = [
  s3topOne,
  s3topTwo,
  s3topThree,
  s3topFour,
  s3topFive,
  s3topSix,
  s3topSeven,
  s3topEight,
  s3topNine,
];
export var s4topArray = [
  s4topOne,
  s4topTwo,
  s4topThree,
  s4topFour,
  s4topFive,
  s4topSix,
  s4topSeven,
  s4topEight,
  s4topNine,
];
